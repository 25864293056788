<template>
  <div>
    <div v-if="!disabled && !hasSingleAction">
      <BaseDropdown v-if="!disabled && !hasSingleAction">
        <template #trigger>
          <BaseButton :icon="'arrow-down'" :label="menuLabel" :custom_style="style" :icon_style="icon_style" type="default" :loading="isLoading" />
        </template>
        <template #menu>
          <ActionsDropdown
            :downloadProcessedPhotos="downloadProcessedPhotos"
            :downloadRawPhotos="downloadRawPhotos"
            :actionsComponent="actionsComponent"
            :handleEditShoot="handleEditShoot"
            :handleCancelShoot="handleCancelShoot"
            :handleDeclineShoot="handleDeclineShoot"
            :handleAcceptShoot="handleAcceptShoot"
            :handleAssignPhotographer="handleAssignPhotographer"
            :handleTriggerPreview="handleTriggerPreview"
            :handleTriggerRawsPreview="handleTriggerRawsPreview"
            :previewImages="previewImages"
            :previewRawImages="previewRawImages"
            :selectedPhotographer="selectedPhotographer"
            :assignLabel="assignLabel"
            :isDownloadDisabled="isDownloadDisabled"
            :isDownloadImagesDisabled="isDownloadImagesDisabled"
            :shoot="shoot"
            :isLoading="isLoading"
          />
        </template>
      </BaseDropdown>
    </div>
    <div v-if="!disabled && hasSingleAction">
      <BaseButton
        :icon="singleAction.icon"
        :label="singleAction.label"
        type="default"
        @click.native="singleAction.actionHandler"
        :disabled="singleAction.isDisabled ? singleAction.isDisabled : false"
      />
    </div>
  </div>
</template>

<script>
// Base component
import BaseButton from '@base/BaseButton.vue';
import BaseDropdown from '@base/BaseDropdown.vue';

// Utils
import helpers from '@utils/helpers';
import ActionsDropdown from './ActionsDropdown.vue';

export default {
  name: 'ActionsButton',
  components: {
    BaseButton,
    BaseDropdown,
    ActionsDropdown
  },
  props: {
    downloadProcessedPhotos: Function,
    downloadRawPhotos: Function,
    handleEditShoot: Function,
    handleCancelShoot: Function,
    handleDeclineShoot: Function,
    handleAcceptShoot: Function,
    handleAssignPhotographer: Function,
    previewImages: Function,
    previewRawImages: Function,
    handleTriggerPreview: Function,
    handleTriggerRawsPreview: Function,
    assignLabel: String,
    selectedPhotographer: Object,
    isDownloadDisabled: Boolean,
    isDownloadImagesDisabled: Boolean,
    actionsComponent: Array,
    shoot: Object,
    isLoading: Boolean
  },
  data() {
    return {
      icon_style: {
        fill: 'black',
        height: '14px',
        width: '14px',
        stroke: 'black'
      },
      style: {
        'background-color': helpers.getCssVariable('--terziary-btn-color'),
        color: helpers.getCssVariable('--primary-text-color'),
        'margin-left': '8px',
        'margin-right': '8px'
      },
      showDropdownMenu: false,
      windowWidth: window.innerWidth,
      menuLabel: 'Shoot actions',
      disabled: false,
      tooltip: '',
      hasSingleAction: false,
      singleAction: {}
    };
  },
  mounted() {
    const actions = ['DOWNLOAD', 'DOWNLOAD_RAWS', 'CANCEL', 'EDIT', 'ACCEPT', 'DECLINE', 'PREVIEW', 'PREVIEW_RAWS'];

    // Filter out 'DOWNLOAD' action if this.isDownloadImagesDisabled is true,
    // and filter out 'PREVIEW' action if this.isDownloadImagesDisabled is false
    const filteredActions = this.actionsComponent.filter(action => {
      if (this.isDownloadImagesDisabled) {
        return action !== 'DOWNLOAD';
      } else {
        return action !== 'PREVIEW';
      }
    });

    // Check if any of these actions exist in the filtered actionsComponent array and if any of these exist make this.disabled = false;
    const hasAnyAction = filteredActions.some(action => actions.includes(action));

    // If any of these actions exist and there's exactly one action, set this.hasSingleAction to true
    this.hasSingleAction = hasAnyAction && filteredActions.length === 1;

    this.disabled = !hasAnyAction;

    // If there's exactly one action, set this.hasSingleAction to true and create the singleAction object
    if (this.hasSingleAction) {
      const singleActionName = filteredActions.find(action => actions.includes(action));
      const singleActionData = this.getActionData(singleActionName);

      this.singleAction = {
        label: singleActionData.label,
        icon: singleActionData.icon,
        actionHandler: singleActionData.actionHandler,
        isDisabled: singleActionData.isDisabled ? singleActionData.isDisabled : false
      };
    } else {
      this.hasSingleAction = false;
      this.singleAction = null;
    }

    if (window.innerWidth < 500 && window.innerWidth > 325) {
      this.menuLabel = 'Actions';
    } else if (window.innerWidth < 325) {
      this.menuLabel = '';
    } else {
      this.menuLabel = 'Shoot actions';
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      if (window.innerWidth < 500 && window.innerWidth > 325) {
        this.menuLabel = 'Actions';
      } else if (window.innerWidth < 325) {
        this.menuLabel = '';
      } else {
        this.menuLabel = 'Shoot actions';
      }
    },
    getActionData(actionName) {
      // Define the data for each action
      const actionData = {
        DOWNLOAD: {
          label: 'Download content',
          icon: 'download',
          actionHandler: this.downloadProcessedPhotos
        },
        DOWNLOAD_RAWS: {
          label: 'Download RAWs',
          icon: 'download',
          actionHandler: this.downloadRawPhotos
        },
        CANCEL: {
          label: 'Cancel',
          icon: 'cancel', // Replace with the appropriate icon name
          actionHandler: this.handleCancelShoot
        },
        EDIT: {
          label: 'Edit',
          icon: 'edit', // Replace with the appropriate icon name
          actionHandler: this.handleEditShoot
        },
        ACCEPT: {
          label: 'Accept',
          icon: 'check', // Replace with the appropriate icon name
          actionHandler: this.handleAcceptShoot
        },
        DECLINE: {
          label: 'Decline',
          icon: 'close', // Replace with the appropriate icon name
          actionHandler: this.handleDeclineShoot
        },
        PREVIEW: {
          label: 'Preview images',
          icon: 'eye', // Replace with the appropriate icon name
          actionHandler: this.previewImages
        },
        PREVIEW_RAWS: {
          label: 'Preview RAWs',
          icon: 'image', // Replace with the appropriate icon name
          actionHandler: this.previewRawImages,
          isDisabled: !this.actionsComponent.includes('PREVIEW_RAWS') || !this.shoot.raws_preview || !this.shoot.raws || this.shoot.raws.length === 0
        }
      };

      return actionData[actionName];
    }
  }
};
</script>
